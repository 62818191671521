import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const tags = data.allDoctagsJson.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Seo title="All posts" />
        <div class="p-20 grid grid-cols-3 gap-2">
        {tags.map(({ node }) => {
          const title = node.Title
          const tagslug = node.Tag.replace(/[^a-z0-9 -]/g, '-')
          .replace(/\s+/g, '-')
          .replace(/-+/g, '-')
          return (
              <Link
                    key={node.Id}
                    className="text-blue-600 shadow-none"
                    to={'/'+tagslug}
                  >
                    <div class="bg-white p-6 rounded-lg shadow-lg">
                    {title}
                    </div>
              </Link>
          )
        })}
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allDoctagsJson{
      edges {
        node {
          Id
          Title
          Tag
        }
      }
    }
  }
`
